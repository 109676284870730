import React, { Text } from 'react';
import '../styles/Research.css';
import zini from '../assets/homepage/zinikus.png';
import Footer from './Footer.jsx';
import data from '../data/data.json';
import Header from './Header';
import {
  Box,
  VStack,
  Button,
  Container,
  Heading,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
// import {
//   Globe,
//   ArrowRight
// } from 'lucide-react';

import '../styles/Research.css';

const Research = () => {
  return (
    <div>
      <Header />
      <div className="button-page-wrapper">
        <div className="button-container">
          <div className="gradient-overlay"></div>
          <div className="content-stack">
            <h1 className="heading">Enquiry for Product Sales</h1>

            <a
              href="https://forms.gle/dSHRr5qYirzjaYAk9"
              target="_blank"
              rel="noopener noreferrer"
              className="button button-purple"
            >
              For India
              <span className="icon arrow-icon"></span>
            </a>

            <a
              href="https://forms.gle/mB8dM8sEzUQ5xjA16"
              target="_blank"
              rel="noopener noreferrer"
              className="button button-blue"
            >
              For Dubai
              <span className="icon arrow-icon"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
