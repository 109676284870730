import React, { useEffect, useRef, useState } from 'react';
import '../styles/Achievements.css';
import { Image, HStack, Button } from '@chakra-ui/react';

import imgInfotech from '../assets/homepage/infotech.jpg';
import imgAgility from '../assets/homepage/agility.jpg';
import imgStartupnews from '../assets/homepage/startupNews.jpg';
import imgOstLab from '../assets/homepage/ostLab.png';
import { Link } from 'react-router-dom';
const Achievements = () => {
  const observerRef = useRef(null);
  useEffect(() => {
    // Create the Intersection Observer instance
    observerRef.current = new IntersectionObserver(entries => {
      // Loop over the entries
      entries.forEach(entry => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation className
          entry.target.classList.add('achieveImage-animation');
        }
      });
    });

    // Observe the elements
    const achieveImage = document.querySelector('.achieveImage');

    if (achieveImage) {
      observerRef.current.observe(achieveImage);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);
  return (
    <div style={{ backgroundColor: 'black' }}>
      {/* <div className="Achieve">
        <div className="divider"></div>
        <h2>
          <a>Achievements</a>
        </h2>
        <div className="achieveImage achieveImage-animation"></div>
      </div> */}
      {/* <div class="g1-divider"></div> */}
      <hr className="defaultHR" />
      <h2 className="partnersH2">
        <a>Our Partners</a>
      </h2>
      <div className="partners">
        <div className="imageInfotech">
          {/* <h3>Our UAE Distribution partner:</h3> */}
          <Link to={'https://www.infotechuae.com/'} target="_blank">
            <Image src={imgInfotech} w="container.md" />
          </Link>
        </div>

        <div className="imageInfotech">
          {/* <h3>Principal Partner:</h3> */}
          <Link to={'https://agilityventures.in/'} target="_blank">
            <Image src={imgAgility} w="container.md" />
          </Link>
        </div>

        <div className="imageInfotech">
          {/* <h3>Media Partner:</h3> */}
          <Link to={'https://startupnews.fyi/'} target="_blank">
            <Image src={imgStartupnews} w="container.md" />
          </Link>
        </div>
        <div className="imageInfotech">
          {/* <h3>Media Partner:</h3> */}
          <Link to={'https://www.ostlabz.co.in/'} target="_blank">
            <Image src={imgOstLab} w="container.md" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
